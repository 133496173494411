import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	RouterProvider
} from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AboutPage from './pages/About/About';
import ProductsPage from './pages/Products/Products';
import ProductsDetailPage from './pages/Products/ProductDetails';
import ContactPage from './pages/Contact/Contact';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import './assets/css/animate.css';
import 'react-toastify/dist/ReactToastify.css';

import ProjectsSection from './components/ProjectsSection/ProjectsSection';
import PageNotFound from './components/PageNotFound/PageNotFound';
import FAQPage from './pages/FAQ/FAQ';
import Blog from './pages/Blog/Blog';
import BlogDetailPage from './pages/Blog/BlogDetails';
import { Provider } from 'react-redux';
import store from './redux/store';

const router = createBrowserRouter([
		{
			path: "/",
			element: <App />
		},
		{
			path: "about",
			element: <AboutPage />
		},
		{
			path: "products",
			element: <ProductsPage />
		},
		{
			path: "products/:id",
			element: <ProductsDetailPage />
		},
		{
			path: "projects",
			element: <ProjectsSection />
		},
		{
			path: "contact",
			element: <ContactPage />
		},
		{
			path: "faq",
			element: <FAQPage />
		},
		{
			path: "blog",
			element: <Blog />
		},
		{
			path: "blog/:id",
			element: <BlogDetailPage />
		},
    {
			path: "*",
			element: <PageNotFound />
		},
		
]);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
		<Provider store={store}>
			<ParallaxProvider>
				<RouterProvider router={router} />
			</ParallaxProvider>
		</Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
