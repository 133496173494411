import { contactData, productsData, socialMediaData } from "../../constants";

const Footer = () => {
		return(
			<>
				<div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
					<div className="container py-5">
							<div className="row g-5">
									<div className="col-lg-4 col-md-6">
											<h4 className="text-white mb-4">Our Contact</h4>
												{
													contactData.map((i) => (
														<p><a key={i.key} className="mb-2" rel="noreferrer" target="_blank" href={i.link}>{i.icon} {i.data}</a></p>
													))	
												}
											<div className="d-flex pt-2">
													{
														socialMediaData.map((data) => (
															<a key={data.key} className="btn btn-square btn-outline-light rounded-circle me-2" href={data.link}>{data.icon}</a>
														))
													}
											</div>
									</div>
									<div className="col-lg-4 col-md-6">
											<h4 className="text-white mb-4">Products</h4>
											{
												productsData.map((data) => (
													<a key={data.key} className="btn btn-link" href={data.buttonLink}>{data.title}</a>
												))
											}
									</div>
									<div className="col-lg-4 col-md-6">
											<h4 className="text-white mb-4">Newsletter</h4>
											<p>Subscribe to our Newsletter.</p>
											<div className="position-relative w-100">
													<input className="form-control bg-light border-light w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
													<button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
											</div>
									</div>
							</div>
					</div>
				</div>
				<div className="container-fluid copyright py-4">
					<div className="container">
							<div className="row">
									<div className="col-md-12 text-center mb-3 mb-md-0">
											&copy; <a className="border-bottom" href="/">Dexterous Dynamics</a> - All Right Reserved.
									</div>
							</div>
					</div>
				</div>
			</>
		)
}

export default Footer;