import { productsData } from "../../constants";
import { FaPlus } from "react-icons/fa6";


const ProductsSection = () => {
  return(
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <p className="fs-5 fw-bold text-primary">Our Products</p>
                <h1 className="display-5 mb-5"> Help Your Business Grow!</h1>
            </div>
            <div className="row g-4">
              {
                productsData.map((data) => (
                  <div key={data.key} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={data.delay}>
                    <div className="service-item rounded d-flex h-100">
                      <div className="service-img rounded">
                        <img className="img-fluid" src={data.image} alt={data.title} />
                      </div>
                      <div className="service-text rounded p-5">
                        <div className="btn-square rounded-circle mx-auto mb-3">
                            {data.icon}
                          {/* <img className="img-fluid" src={data.icon} alt="Icon" /> */}
                        </div>
                        <h4 className="mb-3">{data.title}</h4>
                        <p className="mb-4">{data.description}</p>
                        {/* <a className="btn btn-sm" href={`/products/${data.id}`}><FaPlus className="me-2 text-light" />{data.buttonName}</a> */}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
        </div>
    </div>
  )
}

export default ProductsSection;