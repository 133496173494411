import { FaAngleRight, FaCalendar } from "react-icons/fa6"

const SingleBlogCard = ({id, title, date, content, image}) => {
  const blogDetailLink = "/blog/" + id
  return(
    <div className="single-latest-blog">
      <a href={blogDetailLink}><img src={image} alt="" className="latest-blog-bg" /></a>
      <div className="blog-text-box">
        <h3><a href={blogDetailLink}>{title}</a></h3>
        <p className="blog-meta">
          <span className="date"><FaCalendar /> {date}</span>
        </p>
        <p className="excerpt">{content}</p>
        <a className="btn btn-sm" href={blogDetailLink}>Read More <FaAngleRight className="ms-2 text-light" /></a>
      </div>
    </div>
  )
}

export default SingleBlogCard