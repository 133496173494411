import { useEffect } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import {ToastContainer } from 'react-toastify';

const Layout = ({ children }) => {

  useEffect(() => {
    //Set Page Title.
    document.title = "Dexterous Dynamics"
  
  }, [])

  return (
    <>
      <Header/>
      <main>
        <ToastContainer position="top-right"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          theme="light" 
        />
        {children}
      </main>
      <Footer />
    </>
  )}

export default Layout